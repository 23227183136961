import logo from './logo.svg';
import './App.css';

import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./views/Login";


const App = () => {
   
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<Login />}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
