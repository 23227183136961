import React from "react";

const Header = () => {
    return (<>
        <header className="App-header" style={{
            //textAlign: "center" ,
            //boxShadow: "0px 0px 14px 2px rgb(15 86 179 / 18%)"
        }}>
            <div className="navbar-brand">
        
                <a href="https://nodatanobusiness.com/" className="custom-logo-link" rel="home" aria-current="page"><img
                    width="400" height="163"
                    src="https://nodatanobusiness.com/wp-content/uploads/2021/08/cropped-nodatanobusiness_400.png"
                    className="custom-logo" alt="NoDataNoBusiness"
                    srcSet="https://nodatanobusiness.com/wp-content/uploads/2021/08/cropped-nodatanobusiness_400.png 400w, https://nodatanobusiness.com/wp-content/uploads/2021/08/cropped-nodatanobusiness_400-300x122.png 300w"
                    sizes="(max-width: 400px) 100vw, 400px"/></a>
            </div>
            <h1>Customers portal</h1>
            <h3>Login to continue</h3>
        </header>
        </>);
}

export default Header;